import { eolsBaseApi, withApiErrorLoggingOnly, } from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import { AppConstants } from '../../components/app/app.constants';
export var fetchGroupedFeatureFlags = function (app, nameFilter, groupFilter) {
    if (app === void 0) { app = AppConstants.APP_ID; }
    var url = addSearchParams('/features/groupedFeatures', {
        app: app,
        nameFilter: nameFilter,
        groupFilter: groupFilter
    });
    return withApiErrorLoggingOnly(function () { return eolsBaseApi.get(url); });
};
export var fetchGroupFeatureFlag = function (app, key, group) {
    if (app === void 0) { app = AppConstants.APP_ID; }
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get("/features/app/".concat(app, "/key/").concat(key, "/group/").concat(group));
    });
};
export var postGroupFeatureFlag = function (app, key, group, value) {
    if (app === void 0) { app = AppConstants.APP_ID; }
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.post("/features/app/".concat(app, "/key/").concat(key, "/group/").concat(group), {
            data: value
        });
    });
};
