var _a;
export var NO_VALUE = 'NO_VALUE';
export var FALSE_VALUE = 'false';
export var TRUE_VALUE = 'true';
// TODO: Describe feature flags
export var FEATURE_FLAG;
(function (FEATURE_FLAG) {
    FEATURE_FLAG["ADOBE_ANALYTICS_DISABLED"] = "ADOBE_ANALYTICS_DISABLED";
    FEATURE_FLAG["IS_SITE_DOWN_FOR_MAINTENANCE"] = "IS_SITE_DOWN_FOR_MAINTENANCE";
    FEATURE_FLAG["EXAMPLE_FEATURE_FLAG"] = "EXAMPLE_FEATURE_FLAG";
})(FEATURE_FLAG || (FEATURE_FLAG = {}));
export var AB_TEST = 'AB_TEST';
export var AB_TEST_CONFIG_MAP = (_a = {},
    _a[FEATURE_FLAG.EXAMPLE_FEATURE_FLAG] = [
        'EXAMPLE_FLAVOR_1',
        'EXAMPLE_FLAVOR_2',
    ],
    _a);
