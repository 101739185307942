var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ELSRestClientHelper, ELSCommonConfig, ELSTokenHelper, ELSLoggingService } from '@els/els-ui-common-react';
var fileName = 'api.utilities';
export var buildUrlFromBase = function (url, addBaseUrl, includeApiPath) {
    if (addBaseUrl === void 0) { addBaseUrl = true; }
    if (includeApiPath === void 0) { includeApiPath = true; }
    if (!addBaseUrl) {
        return url;
    }
    if (!includeApiPath) {
        return "".concat(ELSCommonConfig.buildUrl).concat(url);
    }
    return "".concat(ELSCommonConfig.buildUrl, "/api").concat(url);
};
var withApiErrorLoggingOnly = function (promiseFunction) {
    return promiseFunction()
        .then(function (res) { return res.data; })
        .catch(function (error) {
        ELSLoggingService.error(fileName, error.message, error);
        throw error;
    });
};
export var getDefaultRequestHeaders = function (headers) {
    if (headers === void 0) { headers = {}; }
    return __assign({ Authorization: "Bearer ".concat(ELSTokenHelper.getToken()), accept: 'application/json, text/plain, */*' }, headers);
};
export var getDefaultRequestOptions = function (options, headers) {
    if (options === void 0) { options = {}; }
    if (headers === void 0) { headers = {}; }
    return __assign({ headers: getDefaultRequestHeaders(headers) }, options);
};
var RestCall;
(function (RestCall) {
    RestCall["get"] = "get";
    RestCall["post"] = "post";
    RestCall["put"] = "put";
    RestCall["patch"] = "patch";
    RestCall["delete"] = "delete";
})(RestCall || (RestCall = {}));
var eolsBaseApi = Object.values(RestCall).reduce(function (acc, cur) {
    var _a;
    return __assign(__assign({}, acc), (_a = {}, _a[cur] = function (url, options, headers, addBaseUrl, includeApiPath) {
        if (options === void 0) { options = {}; }
        if (headers === void 0) { headers = {}; }
        if (addBaseUrl === void 0) { addBaseUrl = true; }
        if (includeApiPath === void 0) { includeApiPath = true; }
        return ELSRestClientHelper[cur](buildUrlFromBase(url, addBaseUrl, includeApiPath), getDefaultRequestOptions(options, headers));
    }, _a));
}, {});
export { withApiErrorLoggingOnly, eolsBaseApi };
