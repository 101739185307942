import { isDate, isEmpty } from 'lodash';
import { stripNilProps } from './common.utilities';
export var scrubProps = function (obj) {
    if (!obj || isEmpty(obj)) {
        return {};
    }
    var copy = stripNilProps(obj);
    Object.keys(copy).forEach(function (key) {
        if (copy[key] === '') {
            delete copy[key];
        }
        else if (copy[key] === true || copy[key] === false) {
            copy[key] = copy[key].toString();
        }
        else if (isDate(copy[key])) {
            copy[key] = copy[key].getTime();
        }
        else if (!isNaN(copy[key])) {
            copy[key] = parseFloat(copy[key]);
        }
    });
    return copy;
};
// https://github.com/elsevierPTG/els-performance-reports-app/blob/develop/src/services/adobe-analytics.service.ts
// Consider enabling adobe analytics in the future
// export const trackFeature = (feature: AnalyticsAction): void => {
//   ELSAdobeAnalyticService.trackEvent(ELSCommonUIConstants.analytics.eventTypes.productFeatureUsed, {
//     feature: {
//       name: feature
//     }
//   });
// };
