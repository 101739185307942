var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect, } from 'react';
import { ELSTokenServiceRegistrar } from '@els/els-ui-common-react';
import { AppContext } from './AppProvider.component';
import { pickCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
export function AppRegistrarComponent() {
    var setAppState = useContext(AppContext).setAppState;
    useEffect(function () {
        ELSTokenServiceRegistrar.initializeFromReload();
        var cookies = pickCookies();
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { appLinkCookies: cookies.linkId && cookies.token ? cookies : null });
        });
        ELSTokenServiceRegistrar.registerTokenTimeoutCallback(function () {
            // TODO: Fix me
            // navigate(RoutePath.TIMEOUT);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}
var AppRegistrar = AppRegistrarComponent;
export default AppRegistrar;
