var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ELSCommonUIConstants, ELSSecurityRoutes } from '@els/els-ui-common-react';
import { RoutePath } from './app.constants';
import SupportPage from '../../pages/support/Support.page';
import AppLinkRedirectPage from '../../pages/app-link-redirect/AppLinkRedirect.page';
import RemediationSettingsPage from '../../pages/remediation-settings/RemediationSettings.page';
export var AppRoutes = {
    getRoutes: function () { return __spreadArray(__spreadArray([], ELSSecurityRoutes.getSecurityRoutes()
        .filter(function (route) {
        return route.path !== "/".concat(ELSCommonUIConstants.security.States.Admin);
    })
        .map(function (route) {
        console.log(route);
        return __assign(__assign({}, route), { component: _jsx("div", { children: route.path }) });
    }), true), [
        {
            path: RoutePath.APP_LINK_REDIRECT,
            component: _jsx(AppLinkRedirectPage, {}),
            isPrivate: false,
            exact: true,
            data: {
                analytics: {
                    pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
                    pageName: 'ssa:inter-app-redirect',
                }
            }
        },
        {
            path: RoutePath.SUPPORT,
            component: _jsx(SupportPage, {}),
            isPrivate: true,
            exact: true,
            data: {
                analytics: {
                    pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
                    pageName: 'ssa:support',
                }
            }
        },
        {
            path: RoutePath.REMEDIATION_SETTINGS,
            component: _jsx(RemediationSettingsPage, {}),
            isPrivate: true,
            exact: true,
            data: {
                analytics: {
                    pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
                    pageName: 'ssa:remediation-settings',
                }
            }
        },
    ], false); }
};
