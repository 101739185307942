import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
export var fetchCourseSection = function (courseId) {
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get("/courseSections/".concat(courseId));
    });
};
export var fetchUserCourseSections = function (userId, active, entitled) {
    if (active === void 0) { active = true; }
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get(addSearchParams('/courseSections', { entitled: entitled, userId: userId, active: active }));
    });
};
