import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
export var fetchUser = function (userId) {
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get("/eolsUser/user/".concat(userId));
    });
};
export var fetchCrosswalkUser = function (userId, type) {
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get("/eolsUser/".concat(userId, "/type/").concat(type, "/crosswalk"));
    });
};
