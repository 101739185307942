var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { isEqual } from 'lodash';
import { ELSNewRelicBrowserService, ELSCommonConfig } from '@els/els-ui-common-react';
import { getWordMark } from '../../utilities/app.utilities';
/**
 * This is a workaround while waiting the official release.
 * Helmet uses deep-equal and sometimes crashes on circular objects.
 * See: https://github.com/nfl/react-helmet/issues/373
 * The fix is in 6.x beta. Wait for official release
 */
Helmet.prototype.shouldComponentUpdate = function shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
};
var withHTMLHeadSEO = function (data) {
    if (data === void 0) { data = {}; }
    return function (BaseComponent) {
        var SEOComponent = /** @class */ (function (_super) {
            __extends(SEOComponent, _super);
            function SEOComponent() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            SEOComponent.prototype.render = function () {
                var headScripts = [];
                try {
                    var _a = JSON.parse(window.getNewRelicBrowserConfig()), licenseKey = _a.licenseKey, applicationID = _a.applicationID, accountID = _a.accountID, trustKey = _a.trustKey, agentID = _a.agentID;
                    if (licenseKey && applicationID && accountID && trustKey && agentID) {
                        headScripts.push({
                            type: 'text/javascript',
                            innerHTML: ELSNewRelicBrowserService.registerNewRelicBrowser({ licenseKey: licenseKey, applicationID: applicationID, accountID: accountID, trustKey: trustKey, agentID: agentID })
                        });
                    }
                }
                catch (e) {
                    ELSCommonConfig.getLogger().debug("New Relic Browser is not enabled for the current environment - ".concat(e.toString()));
                }
                var pageNamePrefix = getWordMark();
                var title = data && data.title ? "".concat(pageNamePrefix, " - ").concat(data.title) : pageNamePrefix;
                return (_jsxs(_Fragment, { children: [_jsx(Helmet, { title: title, script: headScripts, children: _jsx("html", { lang: "en" }) }), BaseComponent && _jsx(BaseComponent, __assign({}, this.props))] }));
            };
            return SEOComponent;
        }(PureComponent));
        return SEOComponent;
    };
};
export default withHTMLHeadSEO;
