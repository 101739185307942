import { useContext, } from 'react';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from '../app/AppProvider.component';
import { getIsMaintenance } from '../app/app-state-selectors.utilities';
import { RoutePath } from '../app/app.constants';
export function MaintenanceHandlerComponent() {
    var appState = useContext(AppContext).appState;
    var navigate = useNavigate();
    var location = useLocation();
    var isMaintenanceMode = getIsMaintenance(appState);
    var maintenancePath = "/".concat(ELSCommonUIConstants.security.States.Maintenance);
    if (isMaintenanceMode
        && location.pathname !== maintenancePath
        && location.pathname !== RoutePath.TIMEOUT) {
        navigate(maintenancePath);
    }
    return null;
}
var MaintenanceHandler = MaintenanceHandlerComponent;
export default MaintenanceHandler;
