var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { get, } from 'lodash';
import { ELSPendoService, ELSTokenHelper, } from '@els/els-ui-common-react';
import { ELSAnalyticsRegistrarComponent } from '@els/els-component-shared-ts-react';
import { AppConstants } from '../app/app.constants';
import { convertIsbnsToNormalizedString, getApplicationContext } from '../../utilities/app.utilities';
import { isInstructor, isStudent } from '../../utilities/common.utilities';
import { AppContext } from '../app/AppProvider.component';
import { getIsbns } from '../app/app-state-selectors.utilities';
var defaultState = {
    pendoScriptInjected: false
};
export function AnalyticsRegistrarComponent() {
    var _a = useState(defaultState), state = _a[0], setState = _a[1];
    var appState = useContext(AppContext).appState;
    useEffect(function () {
        if (!state.pendoScriptInjected) {
            ELSPendoService.injectScript(AppConstants.PENDO_API_KEY).then(function () {
                setState({ pendoScriptInjected: true });
            });
        }
    }, [state.pendoScriptInjected]);
    var getEvolveUserEmail = function () {
        var evolveUser = appState.evolveUser;
        if (!evolveUser) {
            return null;
        }
        var evolveUserData = JSON.parse(evolveUser.userData);
        if (!evolveUserData || !evolveUserData.email) {
            return null;
        }
        return evolveUserData.email;
    };
    var isbns = appState.isbns, userRole = appState.userRole, currentCourse = appState.currentCourse, userId = appState.userId, evolveUser = appState.evolveUser;
    if (!isbns || !currentCourse || !state.pendoScriptInjected) {
        return _jsx(_Fragment, {});
    }
    if (!evolveUser) {
        return _jsx(_Fragment, {});
    }
    var institution = currentCourse ? currentCourse.institution : null;
    var courseName = currentCourse ? currentCourse.courseName : null;
    var props = {
        isbn: convertIsbnsToNormalizedString(getIsbns(appState)),
        userRole: userRole,
        appName: 'HESI_REACT',
        productName: get(window, 'pageData.page.productName', ''),
        businessUnit: get(window, 'pageData.page.businessUnit', ''),
        visitorId: null,
        pageEnvironment: get(window, 'pageData.page.environment', ''),
        courseId: currentCourse.id.toString(),
        accountName: institution ? institution.name : null,
        accountId: institution ? institution.id.toString() : null,
        evolveUserId: evolveUser ? evolveUser.userName.toString() : null,
        eolsUserId: userId,
        shouldInjectPendoScript: false,
        eolsUserEmail: ELSTokenHelper.getUserEmail(),
        evolveUserEmail: getEvolveUserEmail(),
        isStudent: isStudent(userRole),
        isInstructor: isInstructor(userRole),
        isIncludeAdobeEducationObject: true,
        courseName: courseName,
        departmentId: '',
        departmentName: '',
        application: getApplicationContext()
    };
    return _jsx(ELSAnalyticsRegistrarComponent, __assign({}, props));
}
var AnalyticsRegistrar = AnalyticsRegistrarComponent;
export default AnalyticsRegistrar;
