import Cookie from 'js-cookie';
import { ELSURLHelper, ELSCommonConfig } from '@els/els-ui-common-react';
import { AppLinkCookie } from './app-link-redirect.constants';
import { ServerConstants } from '../../components/app/server.constants';
export var removeCookies = function () {
    var domain = ServerConstants[ELSCommonConfig.appProfile].cookiesDomain;
    Cookie.remove(AppLinkCookie.X_LINK_ID, { path: '/', domain: domain });
    Cookie.remove(AppLinkCookie.X_TOKEN, { path: '/', domain: domain });
};
export var pickCookies = function () {
    var tokenFromUrl = ELSURLHelper.getParameterByName('token');
    var linkIdFromUrl = ELSURLHelper.getParameterByName('linkId');
    if (tokenFromUrl && linkIdFromUrl) {
        removeCookies();
        return {
            token: tokenFromUrl,
            linkId: linkIdFromUrl
        };
    }
    var token = Cookie.get(AppLinkCookie.X_TOKEN);
    var linkId = Cookie.get(AppLinkCookie.X_LINK_ID);
    removeCookies();
    return { token: token, linkId: linkId };
};
