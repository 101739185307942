export var AnalyticsAction;
(function (AnalyticsAction) {
    AnalyticsAction["LOGIN_LAUNCH"] = "LOGIN_LAUNCH";
    AnalyticsAction["APP_LINK_LAUNCH"] = "APP_LINK_LAUNCH";
    AnalyticsAction["APP_LINK_ERROR"] = "APP_LINK_ERROR";
    AnalyticsAction["APP_LINK_OUT"] = "APP_LINK_OUT";
    AnalyticsAction["API_ERROR"] = "API_ERROR";
    AnalyticsAction["JS_ERROR"] = "JS_ERROR";
})(AnalyticsAction || (AnalyticsAction = {}));
export var AdobeAnalyticsAction;
(function (AdobeAnalyticsAction) {
    AdobeAnalyticsAction["ASSIGNMENT_CREATED"] = "assignmentCreated";
    AdobeAnalyticsAction["ASSIGNMENT_STARTED"] = "assignmentStarted";
    AdobeAnalyticsAction["ASSESSMENT_STARTED"] = "assessmentStarted";
    AdobeAnalyticsAction["ASSIGNMENT_COMPLETED"] = "assignmentCompleted";
    AdobeAnalyticsAction["ASSESSMENT_COMPLETED"] = "assessmentCompleted";
})(AdobeAnalyticsAction || (AdobeAnalyticsAction = {}));
