var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get } from 'lodash';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { fetchCrosswalkUser } from '../../apis/eols-user-management-service/eols-user-management-service.utilities';
import { SystemType } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import { removeCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import { AnalyticsAction } from '../../models/analytics.models';
import { fetchAppLinkData, fetchHashLink } from '../../apis/eols-app-link/eols-app-link.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import { getDefaultActionProps } from './app-state-selectors.utilities';
import { fetchGroupedFeatureFlags, fetchGroupFeatureFlag, postGroupFeatureFlag } from '../../apis/eols-features-api/eols-features-api.utilities';
import { getActiveABTestFlags, getRandomABTestFlavor } from '../../utilities/featureFlag.utilities';
import { fetchCourseSection as fetchCourseSectionService } from '../../apis/eols-course-crud/eols-course-crud.utilities';
var fileName = 'app.state.actions.ts';
export var trackAction = function (props) {
    var defaultActionProps = getDefaultActionProps(props.appState);
    var actionProps = scrubProps(props.actionProps.props);
    var _props = __assign(__assign({}, defaultActionProps), actionProps);
    ELSLoggingService.info(fileName, "New Relic PageAction: ".concat(props.actionProps.action, ", ").concat(JSON.stringify(_props)));
    if (!window.newrelic) {
        return;
    }
    window.newrelic.addPageAction(props.actionProps.action, _props);
};
var requestStart = function (props) {
    props.setAppState(function (prevState) {
        return __assign(__assign({}, prevState), { pendingRequestCount: prevState.pendingRequestCount + 1 });
    });
};
var requestEnd = function (setAppState) {
    setAppState(function (prevState) {
        return __assign(__assign({}, prevState), { pendingRequestCount: prevState.pendingRequestCount - 1 });
    });
};
var handleRequestError = function (props) {
    var error = props.error, action = props.action, isTrackRequestCount = props.isTrackRequestCount, setAppState = props.setAppState;
    var url = get(error, 'config.url', '');
    var method = get(error, 'config.method', '');
    var status = get(error, 'response.status', '');
    if (status === 400) {
        // Likely 400 error here due to overloaded headers
        removeCookies();
    }
    trackAction({
        appState: props.appState,
        actionProps: {
            action: AnalyticsAction.API_ERROR,
            props: {
                status: status,
                action: action,
                url: url,
                method: method
            }
        }
    });
    if (isTrackRequestCount) {
        requestEnd(setAppState);
    }
    return Promise.reject(error);
};
export var fetchEvolveUsersAction = function (props) {
    var useCache = props.useCache, appState = props.appState, setAppState = props.setAppState, userId = props.userId;
    if (useCache && appState.evolveUser) {
        return Promise.resolve(appState.evolveUser);
    }
    requestStart({ setAppState: setAppState });
    return fetchCrosswalkUser(userId, SystemType.EVOLVETYPE)
        .then(function (crosswalkUser) {
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { evolveUser: crosswalkUser });
        });
        requestEnd(setAppState);
        return crosswalkUser;
    })
        .catch(function (e) { return handleRequestError({
        appState: appState,
        setAppState: setAppState,
        error: e,
        action: 'fetchCrosswalkUser',
        isTrackRequestCount: true
    }); });
};
export var fetchAppLinkDataAction = function (props) {
    var appLinkId = props.appLinkId, setAppState = props.setAppState, appState = props.appState;
    requestStart({ setAppState: setAppState });
    return fetchAppLinkData(appLinkId)
        .then(function (appLinkData) {
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { appLinkData: appLinkData, pendingRequestCount: prevState.pendingRequestCount - 1 });
        });
        return appLinkData;
    })
        .catch(function (e) { return handleRequestError({
        appState: appState,
        setAppState: setAppState,
        error: e,
        action: 'fetchAppLinkDataAction',
        isTrackRequestCount: true
    }); });
};
export var fetchHashLinkAction = function (props) {
    var linkHash = props.linkHash, appState = props.appState, setAppState = props.setAppState;
    requestStart({ setAppState: setAppState });
    return fetchHashLink(linkHash)
        .then(function (hashLinkDto) {
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { appLinkCookies: hashLinkDto, pendingRequestCount: prevState.pendingRequestCount - 1 });
        });
        return hashLinkDto;
    })
        .catch(function (e) { return handleRequestError({
        appState: appState,
        setAppState: setAppState,
        error: e,
        action: 'fetchHashLinkAction',
        isTrackRequestCount: true
    }); });
};
var postGroupFeatureFlagAction = function (props) {
    var eolsApp = props.eolsApp, featureName = props.featureName, group = props.group, featureValue = props.featureValue;
    return postGroupFeatureFlag(eolsApp, featureName, group, featureValue).then(function () {
        return {
            createdAt: null,
            eolsApp: eolsApp,
            featureName: featureName,
            featureValue: featureValue,
            group: group,
            updatedAt: null
        };
    });
};
var fetchABFlavorAction = function (props) {
    var appState = props.appState, setAppState = props.setAppState, abTestFlag = props.abTestFlag;
    var abTestFlavor = props.appState.abTestFlavors.find(function (flag) {
        return flag.featureName === abTestFlag.featureName;
    });
    if (abTestFlavor) {
        return Promise.resolve(abTestFlavor);
    }
    requestStart({ setAppState: setAppState });
    var app = "STUDENT_STUDY_".concat(abTestFlag.featureName);
    var key = abTestFlag.featureName;
    return fetchGroupFeatureFlag(app, key, appState.courseSectionId)
        .catch(function (error) {
        if (error.response && error.response.status === 404) {
            var newFlavor = getRandomABTestFlavor(abTestFlag);
            if (!newFlavor) {
                ELSLoggingService.error(fileName, "AB test config missing for flag: ".concat(key));
                return Promise.resolve({
                    createdAt: null,
                    eolsApp: app,
                    featureName: key,
                    featureValue: null,
                    group: appState.courseSectionId,
                    updatedAt: null
                });
            }
            return postGroupFeatureFlagAction({
                eolsApp: app,
                featureName: key,
                group: appState.courseSectionId,
                featureValue: newFlavor
            });
        }
        return Promise.reject(error);
    })
        .then(function (response) {
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { pendingRequestCount: prevState.pendingRequestCount - 1, abTestFlavors: __spreadArray(__spreadArray([], prevState.abTestFlavors, true), [
                    response
                ], false) });
        });
        return response;
    })
        .catch(function (e) { return handleRequestError({
        appState: appState,
        setAppState: setAppState,
        error: e,
        action: 'fetchABFlavor',
        isTrackRequestCount: true
    }); });
};
export var fetchAllAppFeatureFlagsAction = function (props) {
    var setAppState = props.setAppState, appState = props.appState;
    if (appState.featureFlagsGrouped) {
        return Promise.resolve(appState.featureFlagsGrouped);
    }
    requestStart({ setAppState: setAppState });
    return fetchGroupedFeatureFlags().then(function (response) {
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { featureFlagsGrouped: response, pendingRequestCount: prevState.pendingRequestCount - 1 });
        });
        var promises = [];
        var activeABTests = getActiveABTestFlags(response, appState.courseSectionId);
        if (activeABTests && activeABTests.length) {
            activeABTests.forEach(function (activeABTest) {
                promises.push(fetchABFlavorAction({
                    abTestFlag: activeABTest,
                    appState: appState,
                    setAppState: setAppState
                }));
            });
        }
        if (promises.length) {
            return Promise.all(promises).then(function () {
                return response;
            });
        }
        return response;
    })
        .catch(function (e) { return handleRequestError({
        appState: appState,
        setAppState: setAppState,
        error: e,
        action: 'fetchAllAppFeatureFlagsAction',
        isTrackRequestCount: true
    }); });
};
export var fetchCourseSectionAction = function (props) {
    var appState = props.appState, setAppState = props.setAppState, courseSectionId = props.courseSectionId, useCache = props.useCache;
    if (!courseSectionId) {
        return Promise.resolve(null);
    }
    if (useCache && appState.currentCourse) {
        return Promise.resolve(appState.currentCourse);
    }
    requestStart({ setAppState: setAppState });
    return fetchCourseSectionService(courseSectionId)
        .then(function (response) {
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { currentCourse: response, pendingRequestCount: prevState.pendingRequestCount - 1 });
        });
        return response;
    })
        .catch(function (e) { return handleRequestError({
        appState: appState,
        setAppState: setAppState,
        error: e,
        action: 'fetchCourseSectionAction',
        isTrackRequestCount: true
    }); });
};
