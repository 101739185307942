var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { ELSCommonUIConstants, ELSLoggingService, ELSPageLoader, ELSTokenHelper, ELSTokenServiceRegistrar } from '@els/els-ui-common-react';
import { useNavigate } from 'react-router';
import { RoutePath } from '../../components/app/app.constants';
import { AppAction, Application } from '../../apis/eols-app-link/eols-app-link.constants';
import { AppLinkRedirectErrorMessage, AppLinkRedirectErrorMessages } from './app-link-redirect.constants';
import { AppContext } from '../../components/app/AppProvider.component';
import { getQueryParam } from '../../utilities/location.utilities';
import { AnalyticsAction } from '../../models/analytics.models';
import { fetchAllAppFeatureFlagsAction, fetchAppLinkDataAction, fetchCourseSectionAction, fetchEvolveUsersAction, fetchHashLinkAction, trackAction } from '../../components/app/app-state-actions.utilities';
var defaultState = {
    hasError: false,
    errorMessage: ''
};
var fileName = 'AppLinkRedirect.page';
export function AppLinkRedirectComponent() {
    var _a = useState(defaultState), state = _a[0], setState = _a[1];
    var navigate = useNavigate();
    var _b = useContext(AppContext), appState = _b.appState, setAppState = _b.setAppState;
    var logError = function (errorType, error) {
        var url = get(error, 'config.url', '');
        var method = get(error, 'config.method', '');
        var status = get(error, 'response.status', '');
        var errorMessage = AppLinkRedirectErrorMessages[errorType];
        trackAction({
            appState: appState,
            actionProps: {
                action: AnalyticsAction.APP_LINK_ERROR,
                props: {
                    type: errorType,
                    url: url,
                    method: method,
                    status: status
                }
            }
        });
        ELSLoggingService.error(fileName, errorMessage);
        setState({
            hasError: true,
            errorMessage: errorMessage
        });
    };
    var handleAppLinkLaunch = function (appLinkData) {
        if ((appLinkData.action === AppAction.ASSIGNMENT_EDIT)
            && appLinkData.targetApp === Application.HESI_REACT) {
            navigate(RoutePath.REMEDIATION_SETTINGS);
            return Promise.resolve();
        }
        navigate(RoutePath.HOME);
        return Promise.resolve();
    };
    var handleAppLinkReturn = function () {
        navigate(RoutePath.HOME);
        return Promise.resolve();
    };
    var launchApp = function (config) {
        var token = config.token, linkId = config.linkId;
        ELSTokenServiceRegistrar.register(token);
        var tokenUser = ELSTokenHelper.getUser();
        var courseSectionId = tokenUser.appParams.courseId ? tokenUser.appParams.courseId.toString() : null;
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { registeredToken: token, userId: tokenUser.userId.toString(), userRole: tokenUser.role, courseSectionId: courseSectionId, isbns: tokenUser.appParams.isbns });
        });
        fetchEvolveUsersAction({
            useCache: false,
            userId: tokenUser.userId.toString(),
            appState: appState,
            setAppState: setAppState
        });
        return fetchAppLinkDataAction({
            appLinkId: linkId,
            appState: appState,
            setAppState: setAppState,
        }).then(function (appLinkData) {
            return Promise.all([
                fetchAllAppFeatureFlagsAction({
                    appState: appState,
                    setAppState: setAppState
                }),
                fetchCourseSectionAction({
                    courseSectionId: courseSectionId,
                    appState: appState,
                    setAppState: setAppState,
                    useCache: false
                })
            ])
                .then(function () {
                trackAction({
                    actionProps: {
                        action: AnalyticsAction.APP_LINK_LAUNCH,
                        props: {
                            linkType: appLinkData.action,
                            srcApp: appLinkData.srcApp,
                            targetApp: appLinkData.targetApp
                        }
                    },
                    appState: appState,
                });
                if (appLinkData.targetApp === Application.HESI_REACT) {
                    return handleAppLinkLaunch(appLinkData);
                }
                if (appLinkData.srcApp === Application.HESI_REACT) {
                    return handleAppLinkReturn();
                }
                return Promise.resolve();
            });
        }).catch(function (error) {
            logError(AppLinkRedirectErrorMessage.REQUEST_FAILED, error);
        });
    };
    var isRedirectValid = function (config) {
        var token = config.token, linkId = config.linkId;
        if (!token || !linkId) {
            logError(AppLinkRedirectErrorMessage.MISSING_COOKIES, null);
            return false;
        }
        var tokenExpireDate = ELSTokenHelper.getExpirationDateFromToken(token);
        var now = moment();
        if (now.isAfter(tokenExpireDate)) {
            logError(AppLinkRedirectErrorMessage.EXPIRED_TOKEN, null);
            return false;
        }
        var tokenUser = ELSTokenHelper.getUserInfoFromToken(token);
        if (isEmpty(tokenUser)) {
            logError(AppLinkRedirectErrorMessage.INVALID_TOKEN, null);
            return false;
        }
        var validRoles = [
            ELSCommonUIConstants.userType.Student,
            ELSCommonUIConstants.userType.Instructor
        ];
        if (!validRoles.includes(tokenUser.role)) {
            logError(AppLinkRedirectErrorMessage.UNSUPPORTED_USER_ROLE, null);
            return false;
        }
        return true;
    };
    useEffect(function () {
        var linkHash = getQueryParam('linkHash');
        if (linkHash) {
            fetchHashLinkAction({
                linkHash: linkHash,
                appState: appState,
                setAppState: setAppState
            }).then(function (response) {
                launchApp(response);
            });
        }
        else if (isRedirectValid(appState.appLinkCookies)) {
            launchApp(appState.appLinkCookies);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (state.hasError) {
        return (_jsx("div", { className: "u-els-padding-3x", children: _jsx("h2", { className: "c-els-heading c-els-heading--h4 u-els-color-primary", children: state.errorMessage }) }));
    }
    return (_jsx("div", { className: "u-els-padding-3x", children: _jsx("h2", { className: "c-els-heading c-els-heading--h4 u-els-color-primary", children: _jsx(ELSPageLoader, {}) }) }));
}
var AppLinkRedirect = AppLinkRedirectComponent;
export default AppLinkRedirect;
