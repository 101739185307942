import moment from 'moment';
export var DATE_FORMAT = 'MM-DD-YYYY';
// TODO: Migrate these date formats to a shared component
export var DATE_PRIMARY_CHUNK = 'MMM. D';
export var DATE_PRIMARY = "".concat(DATE_PRIMARY_CHUNK, ", YYYY");
export var DATE_SECONDARY = 'M/D/YYYY';
export var TIME_PRIMARY = 'h:mma';
export var DATE_TIME_PRIMARY = "".concat(DATE_PRIMARY, " ").concat(TIME_PRIMARY);
export var DATE_TIME_SECONDARY = "".concat(DATE_SECONDARY, " ").concat(TIME_PRIMARY);
export var DAY_DATE_PRIMARY = "dddd, ".concat(DATE_PRIMARY);
export var DAY_DATE_SECONDARY = "dddd, ".concat(DATE_SECONDARY);
export var DAY_DATE_TIME_PRIMARY = "".concat(DAY_DATE_PRIMARY, " ").concat(TIME_PRIMARY);
export var DAY_DATE_TIME_SECONDARY = "".concat(DAY_DATE_SECONDARY, " ").concat(TIME_PRIMARY);
export var DATE_TIME_COPY_COURSE = 'YYYY-MM-DDTHH:mm:ss';
export var INVALID_DATE = moment.invalid().toString();
