import { ELSCommonConfig, ELSTokenHelper } from '@els/els-ui-common-react';
import { get } from 'lodash';
import { convertIsbnsToNormalizedString } from '../../utilities/app.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import { FALSE_VALUE, FEATURE_FLAG, TRUE_VALUE } from '../../apis/eols-features-api/eols-features-api.constants';
import { UNKNOWN } from './app.constants';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
export var getIsbns = function (state) {
    if (!state.isbns) {
        return null;
    }
    return state.isbns.split(',');
};
var getUserEmailDomain = function (appState) {
    var user = appState.eolsUser;
    var emailAddress = !user || !user.emailAddress ? ELSTokenHelper.getUserEmail() : user.emailAddress;
    if (!emailAddress) {
        return UNKNOWN;
    }
    var emailParts = emailAddress.split('@');
    if (!emailParts || emailParts.length !== 2) {
        return UNKNOWN;
    }
    return emailParts[1];
};
var isTestUser = function (appState) {
    var emailDomain = getUserEmailDomain(appState);
    if (emailDomain === UNKNOWN) {
        return UNKNOWN;
    }
    var _isTestUser = ELSCommonConfig.testUserEmailDomains.some(function (domain) {
        return emailDomain.includes(domain);
    });
    return _isTestUser ? TRUE_VALUE : FALSE_VALUE;
};
var getConsumerKey = function (appState) {
    return get(appState, 'currentCourse.consumerKey', '');
};
export var getDefaultActionProps = function (appState) {
    var evolveUser = appState.evolveUser;
    var institution = appState.currentCourse ? appState.currentCourse.institution : null;
    var props = {
        courseSectionId: appState.courseSectionId,
        eolsUserId: appState.userId,
        evolveUserId: evolveUser ? evolveUser.externalUserId : UNKNOWN,
        userRole: appState.userRole,
        isbns: appState.isbns,
        isbnsSorted: convertIsbnsToNormalizedString(getIsbns(appState)),
        institution: institution ? institution.name : null,
        userEmailDomain: getUserEmailDomain(appState),
        isTestUser: isTestUser(appState),
        isMasqueradeUser: ELSTokenHelper.isMasqueradeUser(),
        pathname: window.location.pathname,
        appVersion: window.getBuildVersion ? window.getBuildVersion() : null,
        consumerKey: getConsumerKey(appState)
    };
    return scrubProps(props);
};
export var getIsMaintenance = function (appState) {
    var value = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(appState.featureFlagsGrouped, FEATURE_FLAG.IS_SITE_DOWN_FOR_MAINTENANCE, appState.courseSectionId);
    return value === null ? false : value;
};
