var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useMemo, useState } from 'react';
import { ELSCommonConfig, ELSStorageHelper } from '@els/els-ui-common-react';
import { noop } from 'lodash';
import { PERSIST_KEY } from './app.constants';
import { DefaultAppState } from './app-state.constants';
var defaultContextValue = {
    appState: DefaultAppState,
    setAppState: noop,
};
export var AppContext = createContext(defaultContextValue);
function AppProvider(_a) {
    var children = _a.children;
    var storedAppState = ELSStorageHelper.driver(ELSCommonConfig.storageDriver)
        .namespace('')
        .get(PERSIST_KEY);
    var _b = useState(__assign(__assign({}, DefaultAppState), storedAppState)), appState = _b[0], setAppState = _b[1];
    useEffect(function () {
        ELSStorageHelper.driver(ELSCommonConfig.storageDriver)
            .namespace('')
            .put(PERSIST_KEY, appState);
    }, [appState]);
    var contextValue = useMemo(function () { return ({
        appState: appState,
        setAppState: setAppState,
    }); }, [
        appState,
    ]);
    return (_jsx(AppContext.Provider, { value: contextValue, children: children }));
}
export default AppProvider;
