// https://github.com/elsevierPTG/eols-config-property-store/blob/master/sherpath-course-management-service.yaml
export var Application;
(function (Application) {
    Application["EAQ"] = "EAQ";
    Application["EPM_SKILL"] = "EPM_SKILL";
    Application["KNOWLEDGECARD"] = "KNOWLEDGECARD";
    Application["PERFORMANCE"] = "PERFORMANCE";
    Application["SHER_EVOL"] = "SHER_EVOL";
    Application["SHERPATH"] = "SHERPATH";
    Application["SIMSNG"] = "SIMSNG";
    Application["HESI_NG"] = "HESI_NG";
    Application["HESI_LS"] = "HESI_LS";
    Application["SHADOW_HEALTH"] = "SHADOW_HEALTH";
    Application["NCO"] = "NCO";
    Application["AUTHESS"] = "AUTHESS";
    Application["EAB"] = "EAB";
    Application["STUDENT_STUDY"] = "STUDENT_STUDY";
    Application["HESI_REACT"] = "HESI_REACT";
})(Application || (Application = {}));
export var AppAction;
(function (AppAction) {
    AppAction["ASSESSMENT_GRADE_EDIT"] = "ASSESSMENT_GRADE_EDIT";
    AppAction["ASSESSMENT_PERFORMANCE_VIEW"] = "ASSESSMENT_PERFORMANCE_VIEW";
    AppAction["ASSESSMENT_START"] = "ASSESSMENT_START";
    AppAction["ASSIGNMENT_CREATE"] = "ASSIGNMENT_CREATE";
    AppAction["ASSIGNMENT_EDIT"] = "ASSIGNMENT_EDIT";
    AppAction["ASSIGNMENT_PERFORMANCE_VIEW"] = "ASSIGNMENT_PERFORMANCE_VIEW";
    AppAction["ASSIGNMENT_PREVIEW"] = "ASSIGNMENT_PREVIEW";
    AppAction["PERFORMANCE_VIEW"] = "PERFORMANCE_VIEW";
    AppAction["LAUNCH"] = "LAUNCH";
})(AppAction || (AppAction = {}));
