export var getQueryParams = function () {
    var search = window.location.href.split('?');
    if (search.length < 2) {
        return {};
    }
    var searchParams = new URLSearchParams(search[1]);
    var queryParams = {};
    searchParams.forEach(function (value, key) {
        queryParams[key] = value;
    });
    return queryParams;
};
export var getQueryParam = function (value) {
    return getQueryParams()[value];
};
