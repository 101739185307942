var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { isArray, isEmpty, isNil, isString } from 'lodash';
import messagesDE from '../translations/de';
import messagesEN from '../translations/en';
import { DATE_FORMAT } from '../constants/date.constants';
import { Application } from '../apis/eols-app-link/eols-app-link.constants';
import { EvolveProductTypeKey } from '../apis/eols-course-crud/eols-course-crud.dtos';
export var formatDate = function (str) { return moment(str).format(DATE_FORMAT); };
export var parseDate = function (str) {
    var valid = moment(str, DATE_FORMAT, true).isValid();
    return valid ? moment(str, DATE_FORMAT, true).toDate() : undefined;
};
export var getLocalMomentInsFromServicesUTC = function (date, format) {
    return moment.utc(date, format).local();
};
export var getLocalDateFromServicesUTC = function (date) {
    return getLocalMomentInsFromServicesUTC(date).toDate();
};
export var getWindowMode = function () {
    var innerWidth = window.innerWidth;
    var mode = { mobile: false, tablet: false, desktop: false, wide: false };
    if (innerWidth < 600) {
        return __assign(__assign({}, mode), { mobile: true });
    }
    if (innerWidth >= 600 && innerWidth < 900) {
        return __assign(__assign({}, mode), { tablet: true });
    }
    if (innerWidth >= 900 && innerWidth < 1200) {
        return __assign(__assign({}, mode), { desktop: true });
    }
    if (innerWidth >= 1200) {
        return __assign(__assign({}, mode), { wide: true });
    }
    return mode;
};
export var getTopPosition = function () { return window.pageYOffset || document.documentElement.scrollTop; };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var getCleanSearchParamsFromObject = function (searchParams) {
    var _searchParams = new URLSearchParams();
    Object.keys(searchParams).forEach(function (key) {
        var val = searchParams[key];
        if (isNil(val)) {
            return;
        }
        if (isArray(val) && !val.length) {
            return;
        }
        if (isString(val)) {
            _searchParams.append(key.toString(), val);
        }
        else if (isArray(val)) {
            val.forEach(function (valItem) {
                if (!isNil(valItem)) {
                    _searchParams.append(key.toString(), valItem.toString());
                }
            });
        }
        else {
            _searchParams.append(key.toString(), JSON.stringify(val));
        }
    });
    return _searchParams;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var addSearchParams = function (url, searchParams) {
    if (!searchParams) {
        return url;
    }
    var urlParts = url.split('?');
    var baseUrl = urlParts[0];
    var existingParams = urlParts[1] ? urlParts[1] : '';
    var urlSearchParams = getCleanSearchParamsFromObject(searchParams);
    var combinedParams = existingParams
        ? "".concat(existingParams, "&").concat(urlSearchParams.toString())
        : urlSearchParams.toString();
    if (!combinedParams) {
        return url;
    }
    return "".concat(baseUrl, "?").concat(combinedParams);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var deleteSearchParams = function (url, existingSearchParams, deletingParams) {
    var urlSearchParams = getCleanSearchParamsFromObject(existingSearchParams);
    deletingParams.forEach(function (param) { return urlSearchParams.has(param) && urlSearchParams.delete(param); });
    if (isEmpty(urlSearchParams.toString())) {
        return url;
    }
    return "".concat(url, "?").concat(urlSearchParams.toString());
};
export var isValidDate = function (str, format, strict) {
    if (format === void 0) { format = DATE_FORMAT; }
    if (strict === void 0) { strict = true; }
    return moment(str, format, strict).isValid();
};
/**
 * There are 2 fields in DateTimeComponent: DateComponent and TimeComponent
 * originalDateInput is only bubbled up when we change value in DateComponent
 * originalDateInput is string while value is the unstrictly converted Date so have to validate originalDateInput to ensure correct format
 *
 */
export var validateDateTimeChange = function (value, originalDateInput) {
    if (originalDateInput === undefined) {
        return isValidDate(value);
    }
    return isValidDate(originalDateInput);
};
export var messages = {
    de: messagesDE,
    en: messagesEN
};
export var getLanguage = function () {
    var defaultLanguage = 'en';
    var browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language;
    var supporting = ['en', 'de'];
    if (supporting.includes(browserLanguage)) {
        return browserLanguage;
    }
    browserLanguage = browserLanguage.substring(0, 2);
    if (supporting.includes(browserLanguage)) {
        return browserLanguage;
    }
    return defaultLanguage;
};
export var SELECT_OPTION = {
    name: '--Select--',
    value: ''
};
export var getApplicationContext = function () {
    return Application.HESI_REACT;
};
export var getWordMark = function () {
    return 'HESI';
};
var regexAnyDigit = '\\d';
export var getClientZoneAbbr = function () {
    var clientZone = momentTimezone.tz.guess();
    var currentZoneAbbr = momentTimezone.tz(momentTimezone(), clientZone).zoneAbbr();
    var doesContainDigit = new RegExp(regexAnyDigit).test(currentZoneAbbr);
    if (doesContainDigit) {
        return clientZone;
    }
    return currentZoneAbbr;
};
export var clientTimeZone = momentTimezone.tz.guess();
export var clientZoneAbbr = getClientZoneAbbr();
export var convertIsbnsToNormalizedString = function (isbns) {
    if (!isbns || !isbns.length) {
        return '';
    }
    return isbns.sort().join(',');
};
export var getEntitlements = function (courseSection) {
    return courseSection.entitlements.map(function (entitlement) {
        if (!entitlement || !entitlement.data) {
            return {
                entitlement: entitlement,
                evolveProduct: null
            };
        }
        return {
            entitlement: entitlement,
            evolveProduct: JSON.parse(entitlement.data)
        };
    }).filter(function (item) {
        return item.evolveProduct;
    });
};
export var getIsbnsForVantageCourse = function (tokenIsbns, course) {
    if (!course || !course.entitlements || !course.entitlements.length || !tokenIsbns) {
        return null;
    }
    var tokenIsbnsArr = tokenIsbns.split(',');
    if (!tokenIsbnsArr) {
        return null;
    }
    var entitlements = getEntitlements(course);
    if (!entitlements) {
        return null;
    }
    return tokenIsbnsArr.reduce(function (acc, isbn) {
        var entitlement = entitlements.find(function (_entitlement) {
            return _entitlement.entitlement.isbn === isbn;
        });
        if (!entitlement) {
            return acc;
        }
        if (entitlement.evolveProduct.productTypeKey !== EvolveProductTypeKey.SHERPATH_VANTAGE_IA) {
            return __spreadArray(__spreadArray([], acc, true), [
                isbn
            ], false);
        }
        return entitlement.evolveProduct.realVantageComponent.reduce(function (_acc, component) {
            if (component.productTypeKey.includes('_nss')) {
                return _acc;
            }
            return __spreadArray(__spreadArray([], _acc, true), [
                component.isbn
            ], false);
        }, acc);
    }, []);
};
