var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ELSCommonConfig } from '@els/els-ui-common-react';
var EnvProfile;
(function (EnvProfile) {
    EnvProfile["dev"] = "dev";
    EnvProfile["qa"] = "qa";
    EnvProfile["demo"] = "demo";
    EnvProfile["test1"] = "test1";
    EnvProfile["test2"] = "test2";
    EnvProfile["test3"] = "test3";
    EnvProfile["test4"] = "test4";
    EnvProfile["test5"] = "test5";
    EnvProfile["test6"] = "test6";
    EnvProfile["test7"] = "test7";
    EnvProfile["ck-cert"] = "ck-cert";
    EnvProfile["cd-staging"] = "cd-staging";
    EnvProfile["staging"] = "staging";
    EnvProfile["stage"] = "stage";
    EnvProfile["stage1"] = "stage1";
    EnvProfile["stage2"] = "stage2";
    EnvProfile["validate"] = "validate";
    EnvProfile["vendor"] = "vendor";
    EnvProfile["perf"] = "perf";
    EnvProfile["betaprod"] = "betaprod";
    EnvProfile["prod"] = "prod";
})(EnvProfile || (EnvProfile = {}));
var getSharedLowerEnvConfig = function (env) {
    return {
        gatewayBaseURL: "https://eolsapi-".concat(env, ".apps.els-ols.com"),
        florenceFacadeBaseURL: "https://florence-facade-service-".concat(env, ".apps.els-ols.com"),
        contentBaseURL: 'https://content-qa.els-ols.com',
        cookiesDomain: '.apps.els-ols.com',
        evolveCartURL: 'https://evolvetest.elsevier.com/cs/lms',
        myEvolveUrl: 'https://evolvetest.elsevier.com/cs/myEvolve',
        eolsEnv: '',
        config: {
            PushEventsEnabled: null,
            PushEventsUsesGateway: null,
            TokenServiceEnabled: true,
            CORSAllowCredentials: true,
            EnableLogging: true
        }
    };
};
var envConfigMap = {
    dev: __assign(__assign({}, getSharedLowerEnvConfig(EnvProfile.dev)), { contentBaseURL: 'https://content-dev.els-ols.com/dev' }),
    qa: __assign(__assign({}, getSharedLowerEnvConfig(EnvProfile.qa)), { gatewayBaseURL: 'https://qa-apigateway-eols.elsevier.com' }),
    demo: __assign({}, getSharedLowerEnvConfig(EnvProfile.demo)),
    test1: __assign({}, getSharedLowerEnvConfig(EnvProfile.test1)),
    test2: __assign({}, getSharedLowerEnvConfig(EnvProfile.test2)),
    test3: __assign({}, getSharedLowerEnvConfig(EnvProfile.test3)),
    test4: __assign({}, getSharedLowerEnvConfig(EnvProfile.test4)),
    test5: __assign({}, getSharedLowerEnvConfig(EnvProfile.test5)),
    test6: __assign({}, getSharedLowerEnvConfig(EnvProfile.test6)),
    test7: __assign({}, getSharedLowerEnvConfig(EnvProfile.test7)),
    'ck-cert': __assign({}, getSharedLowerEnvConfig(EnvProfile['ck-cert'])),
    'cd-staging': __assign({}, getSharedLowerEnvConfig(EnvProfile.stage)),
    staging: __assign({}, getSharedLowerEnvConfig(EnvProfile.stage)),
    stage: __assign({}, getSharedLowerEnvConfig(EnvProfile.stage)),
    stage1: __assign({}, getSharedLowerEnvConfig(EnvProfile.stage1)),
    stage2: __assign({}, getSharedLowerEnvConfig(EnvProfile.stage2)),
    validate: __assign(__assign({}, getSharedLowerEnvConfig(EnvProfile.validate)), { eolsEnv: 'validate' }),
    vendor: __assign({}, getSharedLowerEnvConfig(EnvProfile.vendor)),
    perf: __assign(__assign({}, getSharedLowerEnvConfig(EnvProfile.perf)), { gatewayBaseURL: 'https://eolsapi-perf.apps.elsevier.education', contentBaseURL: 'https://content-perf.apps.elsevier.education' }),
    betaprod: __assign(__assign({}, getSharedLowerEnvConfig(EnvProfile.betaprod)), { gatewayBaseURL: 'https://eolsapi-betaprod.apps.elsevier.education', contentBaseURL: 'https://content-betaprod.apps.elsevier.education', config: __assign(__assign({}, getSharedLowerEnvConfig(EnvProfile.betaprod).config), { PushEventsEnabled: true, PushEventsUsesGateway: true }) }),
    prod: {
        gatewayBaseURL: 'https://eolsapi.elsevier.com',
        florenceFacadeBaseURL: 'https://florence-api.elsevier.com',
        contentBaseURL: 'https://eolscontent.elsevier.com',
        evolveCartURL: 'https://evolve.elsevier.com/cs/lms',
        myEvolveUrl: 'https://evolve.elsevier.com/cs/myEvolve',
        cookiesDomain: '.elsevier.com',
        eolsEnv: '',
        config: {
            PushEventsEnabled: null,
            PushEventsUsesGateway: null,
            TokenServiceEnabled: true, // toggle for enabling token service integration
            CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
            EnableLogging: true
        }
    }
};
export var ServerConstants = __assign({ DataSource: ELSCommonConfig.appProfile }, envConfigMap);
