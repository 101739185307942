import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import { ELSIdleProvider, ELSAccessibilityFocusState, } from '@els/els-ui-common-react';
import { ELSModalProvider } from '@els/els-component-modal-react';
import { IntlProvider, } from 'react-intl';
import { setDefaultAppConfig } from './app.config';
import ErrorBoundary from '../error-boundary/ErrorBoundary.component';
import { AppRoutes } from './app.routes';
import { AppConstants, RoutePath, } from './app.constants';
import { getLanguage, messages } from '../../utilities/app.utilities';
import AppProvider from './AppProvider.component';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import AnalyticsRegistrar from '../analytics-registrar/AnalyticsRegistrar.component';
import MessagesRegistrar from '../messages-registrar/MessagesRegistrar.component';
import AppRegistrar from './AppRegistrar.component';
import MaintenanceHandler from '../maintenance-handler/MaintenanceHandler.component';
import LocationChangeHandler from '../location-change-handler/LocationChangeHandler.component';
import spriteContent from '!!raw-loader!@els/els-styleguide-core/images/icon-sprite.svg';
setDefaultAppConfig();
var HTMLHeadSEOComponent = withHTMLHeadSEO(null)(null);
function App() {
    return (_jsxs(AppProvider, { children: [_jsx("div", { style: { display: 'none' }, dangerouslySetInnerHTML: { __html: spriteContent } }), _jsx(ELSModalProvider, { children: _jsx(ErrorBoundary, { children: _jsxs(IntlProvider, { locale: getLanguage(), messages: messages[getLanguage()], children: [_jsx(HTMLHeadSEOComponent, {}), _jsx(AnalyticsRegistrar, {}), _jsx(MessagesRegistrar, {}), _jsx(AppRegistrar, {}), _jsx(ELSAccessibilityFocusState, {}), _jsx(ELSIdleProvider, { timeout: AppConstants.IDLE_TIMEOUT, warningTime: AppConstants.IDLE_TIMEOUT_WARNING_TIME, onSessionTimeout: function () {
                                    if (window.location.pathname !== RoutePath.TIMEOUT) {
                                        window.location.href = RoutePath.TIMEOUT;
                                    }
                                } }), _jsxs(BrowserRouter, { children: [_jsx(MaintenanceHandler, {}), _jsx(LocationChangeHandler, {}), _jsxs(Routes, { children: [AppRoutes.getRoutes()
                                                .map(function (route) {
                                                return (_jsx(Route, { path: route.path, element: route.component }, route.path));
                                            }), _jsx(Route, { path: "*", element: _jsx("div", { children: "Page not found" }) })] })] })] }) }) })] }));
}
export default App;
