import { omit, cloneDeep } from 'lodash';
import { ELSLoggingService } from '@els/els-ui-common-react';
var fileName = 'error.utilities';
var getBrowserDetails = function () { return omit(cloneDeep(navigator), 'mimeTypes'); };
export var logError = function (_a) {
    var err = _a.err, previousState = _a.previousState, previousStateParams = _a.previousStateParams;
    var details = {
        error: err,
        browser: getBrowserDetails(),
        previousState: previousState,
        previousStateParams: previousStateParams
    };
    ELSLoggingService.error(fileName, details);
};
export var promiseErrorHandler = function (error) {
    ELSLoggingService.error(fileName, "Invoke service error: ".concat(error.message), error);
    return { data: [] };
};
export var errorUtils = { logError: logError, promiseErrorHandler: promiseErrorHandler };
