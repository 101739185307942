var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AB_TEST, AB_TEST_CONFIG_MAP, TRUE_VALUE } from '../apis/eols-features-api/eols-features-api.constants';
export var getGroupFeatureFlagWithFallbackToGlobal = function (flags, featureFlag, group) {
    if (!flags) {
        return null;
    }
    var groupMatch = flags.find(function (flag) {
        return flag.featureName === featureFlag && group && flag.groups.includes(group);
    });
    if (groupMatch) {
        return groupMatch;
    }
    return flags.find(function (flag) {
        return flag.featureName === featureFlag && flag.groups.length === 0;
    });
};
export var getBooleanFromGroupFeatureFlagWithFallbackToGlobal = function (flags, featureFlag, group) {
    var flag = getGroupFeatureFlagWithFallbackToGlobal(flags, featureFlag, group);
    if (!flag) {
        // Return null here to force explicit setting of feature flags
        return null;
    }
    return flag.featureValue === TRUE_VALUE;
};
export var getFeatureFlagGroups = function (flags, featureName, featureValue) {
    if (featureValue === void 0) { featureValue = TRUE_VALUE; }
    if (!flags || !flags.length) {
        return [];
    }
    var flag = flags.find(function (featureFlag) {
        return featureFlag.featureName === featureName
            && featureFlag.featureValue === featureValue
            && featureFlag.groups
            && featureFlag.groups.length;
    });
    if (!flag || !flag.groups) {
        return [];
    }
    return flag.groups;
};
export var getRandomABTestFlavor = function (flag, rand) {
    if (rand === void 0) { rand = Math.random(); }
    var possibleFlavors = AB_TEST_CONFIG_MAP[flag.featureName];
    if (!possibleFlavors || !possibleFlavors.length) {
        return null;
    }
    return possibleFlavors[Math.floor(rand * possibleFlavors.length)];
};
export var getABTestFlavor = function (props) {
    var flag = props.flag, appFlags = props.appFlags, abFlags = props.abFlags, group = props.group, defaultFlavor = props.defaultFlavor;
    var appFlag = getGroupFeatureFlagWithFallbackToGlobal(appFlags, flag, group);
    if (appFlag && appFlag.featureValue !== AB_TEST) {
        return appFlag.featureValue;
    }
    var abFlag = abFlags.find(function (_abFlag) {
        return _abFlag.featureName === flag;
    });
    if (abFlag) {
        return abFlag.featureValue;
    }
    return defaultFlavor;
};
export var getABPropsForAnalytics = function (abTestFlavors) {
    if (!abTestFlavors || !abTestFlavors.length) {
        return {};
    }
    return abTestFlavors.reduce(function (acc, cur) {
        var _a;
        return __assign(__assign({}, acc), (_a = {}, _a[cur.featureName] = cur.featureValue, _a));
    }, {});
};
export var getActiveABTestFlags = function (flags, group) {
    if (!flags) {
        return null;
    }
    var groupMatches = flags.filter(function (flag) {
        return flag.featureValue === AB_TEST && group && flag.groups.includes(group);
    });
    var globalMatches = flags.filter(function (flag) {
        if (groupMatches.some(function (_flag) {
            return _flag.featureName === flag.featureName;
        })) {
            return false;
        }
        return flag.featureValue === AB_TEST && flag.groups.length === 0;
    });
    return __spreadArray(__spreadArray([], groupMatches, true), globalMatches, true);
};
export var isFeatureFlag = function (flag, featureFlagsGrouped, courseSectionId) {
    return Boolean(featureFlagsGrouped && courseSectionId
        ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(featureFlagsGrouped, flag, courseSectionId)
        : false);
};
