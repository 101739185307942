var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext, useEffect, } from 'react';
import { injectIntl } from 'react-intl';
import { AppContext } from '../app/AppProvider.component';
import { translateMessages } from '../../translations/message.utilities';
import { StaticMessagesKeys } from '../../translations/message.constants';
export function MessagesRegistrarComponent(props) {
    var setAppState = useContext(AppContext).setAppState;
    useEffect(function () {
        var translatedMessages = translateMessages(StaticMessagesKeys, props.intl);
        setAppState(function (prevState) {
            return __assign(__assign({}, prevState), { messages: translatedMessages });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
}
export default injectIntl(MessagesRegistrarComponent);
