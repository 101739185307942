var _a;
export var AppLinkRedirectErrorMessage;
(function (AppLinkRedirectErrorMessage) {
    AppLinkRedirectErrorMessage["MISSING_COOKIES"] = "MISSING_COOKIES";
    AppLinkRedirectErrorMessage["EXPIRED_TOKEN"] = "EXPIRED_TOKEN";
    AppLinkRedirectErrorMessage["INVALID_TOKEN"] = "INVALID_TOKEN";
    AppLinkRedirectErrorMessage["MISSING_COURSE_SECTION_ID"] = "MISSING_COURSE_SECTION_ID";
    AppLinkRedirectErrorMessage["UNSUPPORTED_USER_ROLE"] = "UNSUPPORTED_USER_ROLE";
    AppLinkRedirectErrorMessage["REQUEST_FAILED"] = "REQUEST_FAILED";
})(AppLinkRedirectErrorMessage || (AppLinkRedirectErrorMessage = {}));
export var AppLinkRedirectErrorMessages = (_a = {},
    _a[AppLinkRedirectErrorMessage.MISSING_COOKIES] = 'Failed to launch by inter app linking: missing cookies',
    _a[AppLinkRedirectErrorMessage.EXPIRED_TOKEN] = 'Failed to launch by inter app linking: expired token',
    _a[AppLinkRedirectErrorMessage.INVALID_TOKEN] = 'Failed to launch by inter app linking: invalid token',
    _a[AppLinkRedirectErrorMessage.MISSING_COURSE_SECTION_ID] = 'Failed to launch by inter app linking: missing courseSectionId',
    _a[AppLinkRedirectErrorMessage.UNSUPPORTED_USER_ROLE] = 'Failed to launch by inter app linking: user role is not supported',
    _a[AppLinkRedirectErrorMessage.REQUEST_FAILED] = 'Failed to launch by inter app linking: request(s) failed',
    _a);
export var AppLinkCookie;
(function (AppLinkCookie) {
    AppLinkCookie["X_TOKEN"] = "x-token";
    AppLinkCookie["X_LINK_ID"] = "x-link-id";
})(AppLinkCookie || (AppLinkCookie = {}));
