var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineMessages } from 'react-intl';
export var LANGUAGE_KEY;
(function (LANGUAGE_KEY) {
    LANGUAGE_KEY["TEST"] = "TEST";
})(LANGUAGE_KEY || (LANGUAGE_KEY = {}));
var messages = Object.keys(LANGUAGE_KEY).reduce(function (acc, cur) {
    var _a;
    return (__assign(__assign({}, acc), (_a = {}, _a[cur] = {
        id: cur,
        defaultMessage: cur,
    }, _a)));
}, {});
export var Translations = defineMessages(messages);
// Define list of static messages that can be translated without passing any value in
export var StaticMessagesKeys = [
    LANGUAGE_KEY.TEST,
];
