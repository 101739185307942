var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component, } from 'react';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { Navigate } from 'react-router';
import { errorUtils } from '../../utilities/error.utilities';
import { AnalyticsAction, } from '../../models/analytics.models';
import { trackAction } from '../app/app-state-actions.utilities';
import { DefaultAppState } from '../app/app-state.constants';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: null, errorInfo: null };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        trackAction({
            appState: DefaultAppState,
            actionProps: {
                action: AnalyticsAction.JS_ERROR,
                props: {
                    errorName: error ? error.name : null,
                    errorMessage: error ? error.message : null,
                    errorStack: errorInfo ? errorInfo.componentStack : null
                }
            }
        });
        errorUtils.logError({ err: error, previousState: errorInfo, previousStateParams: null });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.error) {
            if (this.state.error.name === 'InvalidTokenError') {
                return _jsx(Navigate, { replace: true, to: "/".concat(ELSCommonUIConstants.security.States.NotAuthorized) });
            }
            return (_jsxs("div", { className: "u-els-padding-3x u-els-text-center", children: [_jsx("div", {}), _jsx("h2", { children: "Oops, something went wrong." }), _jsx("div", { children: _jsx("a", { href: "/", children: "Go Home" }) }), _jsxs("details", { className: "u-els-text-left", style: { whiteSpace: 'pre-wrap' }, children: [this.state.error && this.state.error.toString(), _jsx("br", {}), this.state.errorInfo.componentStack] })] }));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(Component));
export default ErrorBoundary;
