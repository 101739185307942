import { ELSCommonConfig, ELSCommonUIConstants, } from '@els/els-ui-common-react';
import { ELSFormFieldConfig } from '@els/els-component-form-field-react';
import { ServicePath } from './app.constants';
import { ServerConstants } from './server.constants';
import { DATE_FORMAT } from '../../constants/date.constants';
export var setDefaultAppConfig = function () {
    ELSCommonConfig.setBuildUrl(ServerConstants[ServerConstants.DataSource].gatewayBaseURL);
    ELSCommonConfig.setLoginPath(ServicePath.loginPath);
    ELSCommonConfig.setUserServicePath(ServicePath.userServicePath);
    ELSCommonConfig.setTokenServicePath(ServicePath.tokenServicePath);
    ELSCommonConfig.setStorageDriver(ELSCommonUIConstants.security.Locker.SecondaryDriver);
    ELSCommonConfig.setAppId('HESI_REACT');
    ELSCommonConfig.addTestUserEmailDomains('osmosis.org');
    ELSCommonConfig.setUseAppParams(true);
    ELSCommonConfig.setI18Messages({
        timeoutHeading: 'You have been logged out due to inactivity. Please log back in to access your course.',
        warningHeading: '',
        warningMessage: 'Due to inactivity, you will be logged out in 60 seconds. Would you like to stay logged in?'
    });
    ELSFormFieldConfig.setI18nConfig({
        dateFormat: {
            primary: DATE_FORMAT
        }
    });
};
