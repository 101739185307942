export var RoutePath;
(function (RoutePath) {
    RoutePath["APP_LINK_REDIRECT"] = "/redirect";
    RoutePath["TIMEOUT"] = "/timedout";
    RoutePath["HOME"] = "/";
    RoutePath["LOGIN"] = "/login";
    RoutePath["LOGOUT"] = "/logout";
    RoutePath["PAGE_NOT_FOUND"] = "/pageNotFound";
    RoutePath["SUPPORT"] = "/support";
    RoutePath["HELP"] = "/help";
    RoutePath["REMEDIATION_SETTINGS"] = "/remediation-settings";
})(RoutePath || (RoutePath = {}));
export var ServicePath = {
    loginPath: '/login-service/login',
    tokenServicePath: '/token-service',
    userServicePath: '/apiv1/eolsUser/list',
};
export var AppConstants = {
    APP_ID: 'HESI_REACT',
    IDLE_TIMEOUT: 1000 * 60 * 60 * 6,
    IDLE_TIMEOUT_WARNING_TIME: 1000 * 60,
    PENDO_API_KEY: '' // '94bb3f52-df30-4f69-4ec8-dceb6bffffbe'
};
export var UNKNOWN = 'UNKNOWN';
export var PERSIST_KEY = 'globalAppState';
