import { useEffect } from 'react';
import { ELSWithModalService } from '@els/els-component-modal-react';
import { useLocation } from 'react-router';
function LocationChangeHandler(props) {
    var location = useLocation();
    useEffect(function () {
        props.modalService.closeAllModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return null;
}
export default ELSWithModalService(LocationChangeHandler);
