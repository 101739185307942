import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { isNil, } from 'lodash';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { getObjectPropsArray } from '../../utilities/common.utilities';
import { AppContext } from '../../components/app/AppProvider.component';
export function SupportComponent() {
    var appState = useContext(AppContext).appState;
    var getCourseDataRows = function () {
        if (!appState.currentCourse) {
            return [];
        }
        return getObjectPropsArray(appState.currentCourse, [], '');
    };
    var getUserDataRows = function () {
        if (!appState.eolsUser) {
            return [];
        }
        return getObjectPropsArray(appState.eolsUser, [], '');
    };
    var renderRow = function (row) {
        return (_jsxs("tr", { className: "c-els-table__row", children: [_jsx("td", { className: "c-els-table__cell", children: row.prop }), _jsx("td", { className: "c-els-table__cell", children: isNil(row.val) ? null : row.val.toString() })] }, row.prop));
    };
    var buildVersion = window.getBuildVersion ? window.getBuildVersion() : null;
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Support" }), _jsx("div", { className: "o-els-container", children: _jsx("span", { children: "App Version: ".concat(buildVersion) }) }), _jsxs("div", { className: "o-els-container", children: [_jsx("h3", { children: "Course info" }), _jsx("table", { className: "c-els-table c-els-table--padding-1o2 c-els-table--vertical-dividers", children: _jsx("tbody", { className: "c-els-table__body", children: getCourseDataRows().map(function (row) {
                                return renderRow(row);
                            }) }) })] }), _jsxs("div", { className: "o-els-container", children: [_jsx("h3", { children: "User info" }), _jsx("table", { className: "c-els-table c-els-table--padding-1o2 c-els-table--vertical-dividers", children: _jsx("tbody", { className: "c-els-table__body", children: getUserDataRows().map(function (row) {
                                return renderRow(row);
                            }) }) })] })] }));
}
export default withHTMLHeadSEO({ title: 'Support' })(SupportComponent);
