export var EvolveProductTypeKey;
(function (EvolveProductTypeKey) {
    EvolveProductTypeKey["SHERPATH_IA"] = "sherpath_ia";
    EvolveProductTypeKey["SHERPATH_BOOK_ORGANIZED"] = "sherpath_book_organized";
    // Non sellable product used mainly to map EAQs to Sherpath courses
    // This name is confusing since it is used for EAQs but it is meant to be read from the EAQ app perspective
    EvolveProductTypeKey["SHERPATH_COMPONENT_NSS"] = "sherpath_component_nss";
    // Non sellable ebook product - not sure why we use this ISBN instead of the vbId here
    EvolveProductTypeKey["SHERPATH_EBOOK_COMPONENT_NSS"] = "sherpath_ebook_component_nss";
    EvolveProductTypeKey["SIMULATION_SIM_CHART_NG"] = "simulation_sim_chart_ng";
    EvolveProductTypeKey["EAQNG_IA"] = "eaqng_ia";
    EvolveProductTypeKey["EAQ_IA"] = "eaq_ia";
    EvolveProductTypeKey["ELSEVIER_ASSESSMENT_BUILDER"] = "elsevier_assessment_builder_ia";
    EvolveProductTypeKey["SHADOW_HEALTH"] = "shadow_health";
    EvolveProductTypeKey["HESI_CASE_STUDY"] = "case_study";
    EvolveProductTypeKey["HESI_PATIENT_REVIEW"] = "patient_reviews";
    EvolveProductTypeKey["HESI_PRACTICE_TEST"] = "practice_test";
    EvolveProductTypeKey["NURSING_CONCEPTS_IA"] = "nursing_concepts_ia";
    EvolveProductTypeKey["HESI_ASSESSMENT_NEXTGEN"] = "hesi_assessment_nextgen";
    EvolveProductTypeKey["SHERPATH_VANTAGE_IA"] = "sherpath_vantage_ia";
    EvolveProductTypeKey["ELSEVIER_AI"] = "elsevier_ai_ia";
})(EvolveProductTypeKey || (EvolveProductTypeKey = {}));
export var EvolveProductType;
(function (EvolveProductType) {
    EvolveProductType["EBOOK"] = "ebook";
    EvolveProductType["EPRODUCT"] = "eproduct";
})(EvolveProductType || (EvolveProductType = {}));
