var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { isArray, isNil, isObject } from 'lodash';
export var isInstructor = function (userRole) { return userRole === ELSCommonUIConstants.userType.Instructor; };
export var isStudent = function (userRole) { return userRole === ELSCommonUIConstants.userType.Student; };
export var flattenTree = function (array, nestProp, level) {
    if (level === void 0) { level = 1; }
    return array.reduce(function (acc, cur) {
        if (cur[nestProp] && cur[nestProp].length) {
            return __spreadArray(__spreadArray(__spreadArray([], acc, true), [cur], false), flattenTree(cur[nestProp], nestProp, level + 1), true);
        }
        return __spreadArray(__spreadArray([], acc, true), [cur], false);
    }, []);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var stripNilProps = function (obj) {
    var copy = __assign({}, obj);
    Object.keys(copy).forEach(function (key) {
        if (isNil(copy[key])) {
            delete copy[key];
        }
    });
    return copy;
};
export var isOnlyNumber = function (str) {
    var pattern = /^[0-9]*$/gm;
    return pattern.test(str);
};
export var convertStringForClass = function (string) {
    return string
        .toLowerCase()
        .replace(new RegExp(' ', 'g'), '-')
        .replace(new RegExp(/\/|\(|\)/g, 'g'), '');
};
export var mapToIds = function (x) { return x.id; };
export var joinListCommaSeparated = function (items) {
    if (!items || !items.length) {
        return '';
    }
    if (items.length === 1) {
        return items[0];
    }
    return items.reduce(function (acc, cur, idx, arr) {
        if (idx === 0) {
            return cur;
        }
        if (idx + 1 === arr.length) {
            return "".concat(acc, " & ").concat(cur);
        }
        return "".concat(acc, ", ").concat(cur);
    });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var stitchArrays = function (arrays, currentIndex, output) {
    if (currentIndex === void 0) { currentIndex = 0; }
    if (output === void 0) { output = []; }
    if (arrays.every(function (array) {
        return !array.length;
    })) {
        return output;
    }
    var nextIndex = currentIndex + 1 === arrays.length ? 0 : currentIndex + 1;
    if (!arrays[currentIndex].length) {
        return stitchArrays(arrays, nextIndex, output);
    }
    var nextItem = arrays[currentIndex].shift();
    return stitchArrays(arrays, nextIndex, __spreadArray(__spreadArray([], output, true), [nextItem], false));
};
export var truncateTitle = function (title, maxLength) {
    if (isNil(maxLength) || !title || !title.length || title.length <= maxLength) {
        return title;
    }
    return "".concat(title.substring(0, maxLength - 3), "...");
};
export var getObjectPropsArray = function (propVal, acc, path) {
    if (acc === void 0) { acc = []; }
    if (path === void 0) { path = ''; }
    if (isArray(propVal)) {
        return propVal.reduce(function (_acc, cur, idx) {
            return getObjectPropsArray(cur, _acc, path ? "".concat(path, "[").concat(idx, "]") : "[".concat(idx, "]"));
        }, acc);
    }
    if (!isObject(propVal)) {
        return __spreadArray(__spreadArray([], acc, true), [
            {
                prop: path,
                val: propVal
            }
        ], false);
    }
    return Object.keys(propVal).reduce(function (_acc, prop) {
        if (isArray(propVal[prop]) || isObject(propVal[prop])) {
            return getObjectPropsArray(propVal[prop], _acc, path ? "".concat(path, ".").concat(prop) : prop);
        }
        return __spreadArray(__spreadArray([], _acc, true), [
            {
                prop: path ? "".concat(path, ".").concat(prop) : prop,
                val: propVal[prop]
            }
        ], false);
    }, acc);
};
export var filterBySearchQuery = function (searchQuery, wordsToSearch) {
    if (!searchQuery || !searchQuery.length) {
        return true;
    }
    if (!wordsToSearch || !wordsToSearch.length) {
        return false;
    }
    var searchQueryWordList = searchQuery.toLowerCase().split(' ').map(function (item) { return item.trim(); });
    if (!searchQueryWordList || !searchQueryWordList.length) {
        return true;
    }
    var wordsToSearchNormalized = wordsToSearch.map(function (item) { return item.toLowerCase().trim(); });
    return searchQueryWordList.every(function (searchWord) {
        if (searchWord === '') {
            return true;
        }
        return wordsToSearchNormalized.some(function (word) {
            return word.includes(searchWord);
        });
    });
};
export var isTrueValue = function (val) {
    if (isNil(val)) {
        return false;
    }
    return val.toString().toLowerCase() === 'true';
};
export var copyContentToClipboard = function (content) {
    var textArea = document.createElement('textarea');
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
};
