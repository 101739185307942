import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
import { Application, } from './eols-app-link.constants';
import { addSearchParams } from '../../utilities/app.utilities';
export var getAppLinkApiUrl = function (config) {
    var app = config.app, action = config.action;
    if (app === Application.EPM_SKILL) {
        return "/sherpath/course-management/skill/app-link/".concat(action);
    }
    var queryParams = {
        linkId: config.linkId,
        includeLinkHash: config.includeLinkHash,
        isCrossDomain: config.isCrossDomain,
        altSrcApp: config.altSrcApp,
        parentLinkId: config.parentLinkId
    };
    return addSearchParams("/app-link/out/".concat(app, "/").concat(action), queryParams);
};
export var getExternalAppRedirectUrl = function (config, token) {
    var headers = token ? {
        Authorization: "Bearer ".concat(token)
    } : {};
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.post(getAppLinkApiUrl(config), {
            ignoreToken: true,
            data: config.body
        }, headers);
    });
};
export var getLinkNavigation = function (props) {
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.post("/app-link/return/".concat(props.linkId), props.returnPostBody ? { data: props.returnPostBody } : null);
    });
};
export var fetchHashLink = function (hashLink) {
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get("/app-link/hash-link/".concat(hashLink), {
            headers: {
                'X-App-ID': Application.HESI_REACT
            }
        });
    });
};
export var fetchAppLinkData = function (key) {
    return withApiErrorLoggingOnly(function () {
        return eolsBaseApi.get("/app-link/data/".concat(key));
    });
};
